import { useState, Fragment, useRef, useEffect } from "react";
import { useOnClickOutside } from "hooks";
import { ReactComponent as BrandLogo } from "assets/icons/logo.svg";
import { ReactComponent as ChatbotIcon } from "assets/icons/Chatbot/chatbot-icon.svg";
import { ReactComponent as ChatbotSendIcon } from "assets/icons/Chatbot/chatbot-send-icon.svg";
import {
  Trigger,
  Container,
  TopBar,
  Header,
  Body,
  Actions,
  Input,
  SendButton,
  Time,
  ChatContainer,
  MessageContainer,
  Message,
  SuggestionContainer,
  Suggestion,
} from "./Chatbot.styles";
import { formatRelative } from "date-fns";

enum MessageType {
  outgoing = "outgoing",
  incoming = "incoming",
}

type TChat = {
  time?: Date;
  image?: string;
  messages: string[];
  suggestions?: string[];
  type: MessageType;
};

const initialState: TChat[] = [
  {
    time: new Date(),
    messages: [
      "Hi there, Hope you are finding our content useful 😀",
      "What are you looking for?",
    ],
    suggestions: ["Twitter", "Product demand"],
    type: MessageType.incoming,
  },
];

const Chatbot = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [data, setData] = useState<TChat[]>(initialState);
  const [userText, setUserText] = useState("");

  const outSideClickRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setData([
      ...data,
      {
        messages: [userText],
        type: MessageType.outgoing,
      },
    ]);

    setUserText("");
  };

  const handleSuggestionClick = (value: string) => {
    setData([
      ...data,
      {
        messages: [value],
        type: MessageType.outgoing,
      },
    ]);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const handleClickOutside = () => {
    if (showChatbot) {
      setShowChatbot(false);
    }
  };

  useOnClickOutside(outSideClickRef, handleClickOutside);

  return (
    <div ref={outSideClickRef}>
      <Trigger
        icon
        size="massive"
        variant="alpha"
        content={<ChatbotIcon />}
        onClick={() => setShowChatbot((oldState) => !oldState)}
      />
      <Container hidden={!showChatbot}>
        <TopBar>
          <BrandLogo />
          <Header>NULL Innovation</Header>
        </TopBar>
        <Body>
          {data.map(({ time, type, messages, suggestions }, key) => (
            <Fragment key={key}>
              {time && (
                <Time>{formatRelative(time, new Date()).toUpperCase()}</Time>
              )}
              <ChatContainer outgoing={type === MessageType.outgoing}>
                <BrandLogo />
                <MessageContainer>
                  {messages.map((text, key) => (
                    <Message key={key} ref={scrollRef}>
                      {text}
                    </Message>
                  ))}
                  <SuggestionContainer>
                    {suggestions?.map((text, key) => (
                      <Suggestion
                        key={key}
                        onClick={() => handleSuggestionClick(text)}
                      >
                        {text}
                      </Suggestion>
                    ))}
                  </SuggestionContainer>
                </MessageContainer>
              </ChatContainer>
            </Fragment>
          ))}
        </Body>
        <Actions onSubmit={handleSendMessage}>
          <Input
            placeholder="Start typing ..."
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
          />
          <SendButton
            icon
            size="mini"
            variant="alpha"
            type="submit"
            content={<ChatbotSendIcon />}
          />
        </Actions>
      </Container>
    </div>
  );
};

export default Chatbot;
