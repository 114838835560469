import styled from "styled-components";
import { Menu as BaseMenu } from "semantic-ui-react";

const Menu = styled(BaseMenu)`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    margin: 0 0 1rem 0;
    .item {
      color: ${({ theme }) => theme.beta};

      &:hover {
        background-color: unset;
        color: ${({ theme }) => theme.beta};
      }
      &.active {
        color: ${({ theme }) => theme.beta};
        background-color: unset;
        position: relative;
        ::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 2px;
          background-color: ${({ theme }) => theme.beta};
        }
      }
    }
    @media screen and (max-width: 768px) {
      &&& {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        .left.item,
        .right.menu {
          margin-left: unset !important;
          margin-right: unset !important;
        }
      }
    }
  }
`;

export { Menu };
