import { TeamSection, Heading } from "./TeamSection.styles";
import team from "config/About/team.data";
import MemberCard from "./Card";
import { Container } from "semantic-ui-react";

function App() {
  return (
    <Container>
      <Heading>Ours Team</Heading>
      <TeamSection>
        {team.map((member) => (
          <MemberCard
            key={member.id}
            name={member.name}
            role={member.discripation}
            pic={member.pic}
            link={member.link}
          />
        ))}
      </TeamSection>
    </Container>
  );
}

export default App;
