const data = {
  "about-us":
    "Null Innovation is a technology company developing products and providing services in the areas of the Internet of Things and Data Science. We are developing products that will help people to accelerate IoT development, make Digital Marketing data smart and monitor vital signs to save a life.",

  mission:
    "As a company, we aim at learning what is available in the market at a greater speed and adding our innovation on the top to build the best solution of the problem. We constantly raise our bar and strive to achieve our objectives by continuously exploring new technologies and collaborating with research institutions.The engineering DNA of the company, fun work culture and level 5 leadership ensure our success ",

  vision:
    "Our vision is to strengthen the foundation of IoT and AI to increase the adoption of technology in various industries, ultimately achieve the betterment of lives.",
};

export default data;
