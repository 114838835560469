import { useEffect, useState } from "react";
import { SelectorContainer, Selector } from "./Carousel.styles";

interface IProps {
  onChange: (id: number) => void;
}

const MAX_SELECTORS = 3;

const Carousel = ({ onChange }: IProps) => {
  const [activeId, setActiveId] = useState(1);

  useEffect(() => {
    onChange(activeId);
  }, [activeId, onChange]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveId((current) => (current < MAX_SELECTORS ? current + 1 : 1));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SelectorContainer>
      <Selector active={activeId === 1} onClick={() => setActiveId(1)} />
      <Selector active={activeId === 2} onClick={() => setActiveId(2)} />
      <Selector active={activeId === 3} onClick={() => setActiveId(3)} />
    </SelectorContainer>
  );
};

export default Carousel;
