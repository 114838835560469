import {
  AwardsSection,
  EcosystemSection,
  HeroSection,
  TeamSection,
} from "components/About";
import { Footer, Navbar } from "components/common";

const About = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <EcosystemSection />
      <TeamSection />
      <AwardsSection />
      <Footer />
    </>
  );
};

export default About;
