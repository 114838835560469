import { Route, Switch } from "react-router";
import * as routes from "config/common/routes.config";
import { CoWorkSpace, Home, About } from "pages";

const RootRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.home} component={Home}></Route>
      <Route
        exact
        path="/co-working-space-in-Ahmedabad"
        component={CoWorkSpace}
      />
      <Route exact path={routes.about} component={About} />
    </Switch>
  );
};

export default RootRoutes;
