import styled from "styled-components";

const SectionContainer = styled.section`
  margin: 72px auto;
  width: 76%;

  @media screen and (max-width: 767px) {
    width: 83.4%;
  }
`;

const Benefit = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  column-gap: 48px;
  row-gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
    column-gap: unset;
    row-gap: 16px;
  }
`;

const BenefitHeading = styled.h2`
  text-align: center;
  font-size: 40px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    margin: unset;
  }
`;

const BenefitPicture = styled.img`
  height: 100%;
  width: 100%;
`;

export { SectionContainer, Benefit, BenefitHeading, BenefitPicture };
