import { Button as BaseButton } from "components/core";
import { lighten } from "polished";
import styled, { css } from "styled-components";

type TChatContainer = {
  outgoing?: boolean;
};

const Trigger = styled(BaseButton)`
  &&&& {
    position: fixed;
    right: 2%;
    bottom: 5%;
    z-index: 2;
    border-radius: 50px;
    padding: 1.2rem;
    svg {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  right: 10%;
  bottom: 15%;
  z-index: 2;
  height: min(65vh, 480px);
  width: min(80vw, 400px);
  box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};
`;

const TopBar = styled.div`
  background-color: ${({ theme }) => theme.alpha};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  border-radius: 25px 25px 0px 0px;
  padding: 0.5rem 1rem;
  svg {
    width: 45px;
  }
`;

const Header = styled.h1`
  font-family: ${({ theme }) => theme.gammaFont};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.white};
  margin: unset;
`;

const Body = styled.div`
  height: 80%;
  overflow-y: auto;
  svg {
    width: 50px;
  }
  svg,
  svg rect {
    fill: ${({ theme }) => theme.alpha};
  }
`;

const Actions = styled.form`
  margin: auto 0.5rem 0.5rem 0.5rem;
  background-color: ${({ theme }) => lighten("0.7", theme.alpha)};
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;

const Input = styled.input`
  width: 90%;
  background-color: ${({ theme }) => lighten("0.7", theme.alpha)};
  outline: unset;
  border: unset;
  padding: 0 1rem;
`;

const SendButton = styled(BaseButton)`
  &&&& {
    border-radius: 50px;
    background-color: ${({ theme }) => theme.white};
    padding: 0.5rem 1rem;
    svg {
      width: 90%;
      fill: ${({ theme }) => theme.alpha};
    }
  }
`;

const Time = styled.div`
  font-family: ${({ theme }) => theme.alphaFont};
  font-size: 0.7rem;
  text-align: center;
  color: ${({ theme }) => theme.lightGrey};
  padding: 2rem 0;
`;

const ChatContainer = styled.div<TChatContainer>`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  max-width: 90%;
  margin: auto;
  margin-bottom: 1rem;
  ${({ outgoing }) =>
    outgoing &&
    css`
      flex-direction: row-reverse;
      svg {
        display: none;
      }
    `}
`;

const MessageContainer = styled.div`
  max-width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Message = styled.div`
  background-color: ${({ theme }) => lighten("0.7", theme.alpha)};
  min-height: 1rem;
  border-radius: 10px;
  padding: 1rem;
`;

const SuggestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Suggestion = styled.div`
  min-height: 1rem;
  border-radius: 25px;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.alpha};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.alpha};
    color: ${({ theme }) => theme.white};
  }
`;

export {
  Trigger,
  Container,
  TopBar,
  Header,
  Body,
  Actions,
  Input,
  SendButton,
  Time,
  ChatContainer,
  MessageContainer,
  Message,
  SuggestionContainer,
  Suggestion,
};
