import { lighten } from "polished";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ theme }) => lighten("0.7", theme.alpha)};
  padding: 2rem 0;
  margin-bottom: 8rem;
`;

const InnerContainer = styled.div`
  background-color: ${({ theme }) => lighten("0.7", theme.alpha)};
  max-width: 80%;
  margin: auto;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`;

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));
  gap: 5rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

export { Container, InnerContainer, TextContainer, FeaturesContainer, Actions };
