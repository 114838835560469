import { Footer } from "components/common";
import {
  BenefitSection,
  ContactSection,
  Header,
  HeroSection,
  PlanSection,
  PropositionSection,
} from "components/CoWorkSpace";

const CoWorkSpace = () => {
  return (
    <main>
      <Header />
      <HeroSection />
      <PropositionSection />
      <BenefitSection />
      <PlanSection />
      <ContactSection />
      <Footer />
    </main>
  );
};

export default CoWorkSpace;
