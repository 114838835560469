import styled from "styled-components";

const Description = styled.p`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 1.7rem;
  && {
    line-height: 167%;
    @media screen and (max-width: 768px) {
      font-size: calc((50vw + 50vh) / 40 + 2px);
    }
  }
`;

export default Description;
