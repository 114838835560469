import {
  Container,
  Card,
  CardItem,
  Image,
  Description,
} from "./ResearchSection.styles";
import researchIrjet from "assets/images/Home/reasearch-irjet.png";
import researchIjcr from "assets/images/Home/research-ijcr.png";
import researchJetir from "assets/images/Home/research-jetir.png";

const data = [
  {
    id: 1,
    description: `A research paper published on Survivr titled “IoT based Smart healthcare Band” in the Journal of Emerging Technologies and Innovation Research (JETIR)`,
    image: researchJetir,
  },
  {
    id: 2,
    description: `Our continued R&D led us to publish research paper on FrameGEN titled “Software-based IoT Framework Generation” in the International Research Journal of Engineering and Technology (IRJET)`,
    image: researchIrjet,
  },
  {
    id: 3,
    description: `We are pioneer in Innovation and published research paper titled “Wireless System For Touchless Shopping Using IoT” published the International Journal of Creative Research.`,
    image: researchIjcr,
  },
];

const ResearchSection = () => {
  return (
    <Container>
      <Card>
        <CardItem>
          <Image src={data[0].image} />
          <Description>{data[0].description}</Description>
        </CardItem>
        <CardItem>
          <Image src={data[1].image} />
          <Description>{data[1].description}</Description>
        </CardItem>
        <CardItem>
          <Image src={data[2].image} />
          <Description>{data[2].description}</Description>
        </CardItem>
      </Card>
    </Container>
  );
};

export default ResearchSection;
