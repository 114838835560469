import styled from "styled-components";

const Description = styled.p`
  font-size: 20px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Heading = styled.h2`
  color: ${({ theme }) => theme.beta};
  font-size: 40px;
  text-align: center;
  margin: 48px auto 16px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export { Description, Heading };
