import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ theme }) => theme.beta};
`;

const InnerContainer = styled.div`
  padding: 5rem 0;
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;

  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`;

const CompanyInfoContainer = styled.div``;

const Header = styled.h2`
  text-transform: uppercase;
  margin-bottom: 3rem;
`;

const Description = styled.p`
  max-width: 200px;
`;

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.2rem;
  padding: 2rem 0;
  svg {
    cursor: pointer;
  }
`;

const CopyRightTextContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.beta};
  color: ${({ theme }) => theme.white};
  padding: 1rem 0;
  font-size: 1rem;
`;

export {
  Container,
  CompanyInfoContainer,
  Header,
  Description,
  SocialLinksContainer,
  CopyRightTextContainer,
  InnerContainer,
};
