import { SectionContainer } from "./PropositionSection.styles";
import { Description } from "./Common.styles";

const PropositionSection = () => {
  return (
    <SectionContainer>
      <Description>
        Are you a startup or entrepreneur looking for a productive and
        collaborative workspace? Look no further than NULL Co-working. Our
        quite, cohesive facilities at Science City Road, Ahmedabad offer a
        variety of flexible options to suit your needs, whether you're a
        freelancer, small business owner, or part of a growing startup.
      </Description>
    </SectionContainer>
  );
};

export default PropositionSection;
