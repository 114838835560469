import React, { createContext, useState } from "react";
import { subscribe as subscribeApi } from "api";
import type { IError, IResponse } from "api/Home/subscribe";
import { useMutation, UseMutateFunction } from "react-query";

interface IContext {
  subscribe: UseMutateFunction<IResponse, IError, string, unknown>;
  isLoading: boolean;
  success: string;
  error: string;
}

interface IProps {
  children: React.ReactNode;
}

export const SubscribeContext = createContext<IContext>({
  subscribe: () => {},
  isLoading: false,
  success: "",
  error: "",
});

const SubscribeProvider = (props: IProps) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { mutate: subscribe, isLoading } = useMutation<
    IResponse,
    IError,
    string,
    unknown
  >(subscribeApi, {
    onSuccess: (data) => setSuccess(data.message),
    onError: (data) => setError(data.message),
  });
  return (
    <SubscribeContext.Provider value={{ subscribe, isLoading, success, error }}>
      {props.children}
    </SubscribeContext.Provider>
  );
};

export default SubscribeProvider;
