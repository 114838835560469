import { lighten } from "polished";
import styled, { css } from "styled-components";

type SelectorProps = {
  active?: boolean;
};

const SelectorContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
`;

const Selector = styled.div<SelectorProps>`
  height: 5px;
  width: 60px;
  cursor: pointer;
  ${({ theme, active }) => {
    if (!active) {
      return css`
        background-color: ${lighten("0.3", theme.beta)};
      `;
    }
    return css`
      background-color: ${theme.beta};
    `;
  }}
`;

export { SelectorContainer, Selector };
