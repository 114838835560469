import { useCallback, useState } from "react";
import {
  Container,
  Card,
  CardItem,
  CardItem1,
  CardItem2,
  Image,
  Title,
  Description,
  Year,
  Description1,
  Description2,
  CarouselContainer,
} from "./AwardsSection.styles";
import { Carousel } from "components/common";
import awardsIcon from "assets/images/Home/award-icon.png";

interface CardData {
  id: number;
  title: string;
  by: string;
  year: string;
  image: string;
}

const cardsData: CardData[] = [
  {
    id: 1,
    title: `Top 20 IoT Companies`,
    by: `by CIOReview`,
    year: ` in 2020 `,
    image: awardsIcon,
  },
  {
    id: 2,
    title: `Top-performing companies during covid-19 `,
    by: `by TheCEOStory`,
    year: ``,
    image: awardsIcon,
  },
  {
    id: 3,
    title: `10 Most leading Tech Startups`,
    by: `by Swiftnlift`,
    year: `in 2020 `,
    image: awardsIcon,
  },
  {
    id: 4,
    title: `India 500 Startup Award`,
    by: `by India 5000 MSMEs`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 5,
    title: `Best IoT Solution providers in Asia`,
    by: `by TheSiliconReview`,
    year: ` 2020 `,
    image: awardsIcon,
  },
  {
    id: 6,
    title: `Excellence in Data Management & Analytics`,
    by: `by CorporateVision, UK`,
    year: `2020 `,
    image: awardsIcon,
  },
  {
    id: 7,
    title: `The 10 Most Inspiring Business Leaders to follow 2020`,
    by: `by Prime Insights`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 8,
    title: `20 Innovative Companies in India 2020`,
    by: `by Prime Insights`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 9,
    title: `Most promising 100`,
    by: `by SiliconIndia`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 10,
    title: `Startup of the year 2020`,
    by: `by BusinessConnect`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 11,
    title: `Advanced AI powered solutions`,
    by: `by Healthcaretechoutlook`,
    year: ` `,
    image: awardsIcon,
  },
  {
    id: 12,
    title: `Entrepreuner in focus 2020`,
    by: `by Prime insights`,
    year: ``,
    image: awardsIcon,
  },
  {
    id: 13,
    title: `10 Most Emerging companies to watch in 2021`,
    by: `by Prime Insights`,
    year: ``,
    image: awardsIcon,
  },
  {
    id: 14,
    title: `20 most promising technology companies founded and managed by indians in the USA`,
    by: `by SiliconIndia`,
    year: ``,
    image: awardsIcon,
  },
];

const AwardsSection = () => {
  const [data, setData] = useState<
    [CardData, CardData, CardData, CardData, CardData]
  >([
    { id: 1, title: "", by: "", year: "", image: "" },
    { id: 2, title: "", by: "", year: "", image: "" },
    { id: 1, title: "", by: "", year: "", image: "" },
    { id: 1, title: "", by: "", year: "", image: "" },
    { id: 1, title: "", by: "", year: "", image: "" },
  ]);

  const handleChange = useCallback((val: number) => {
    const newData = cardsData.filter(
      ({ id }) =>
        id === val ||
        id === val + 1 ||
        id === val + 2 ||
        id === val + 3 ||
        id === val + 4
    );
    setData(() => [newData[0], newData[1], newData[2], newData[3], newData[4]]);
  }, []);

  return (
    <Container>
      <Card>
        <CardItem2>
          <Image src={data[0].image} />
          <Description2>
            <Title>{data[1].title}</Title>
            {data[0].by}
            <Year>{data[1].year}</Year>
          </Description2>
        </CardItem2>

        <CardItem1>
          <Image src={data[1].image} />
          <Description1>
            <Title>{data[1].title}</Title>
            {data[1].by}
            <Year>{data[1].year}</Year>
          </Description1>
        </CardItem1>

        <CardItem>
          <Image src={data[2].image} />
          <Description>
            <Title>{data[2].title}</Title>
            {data[2].by}
            <Year>{data[2].year}</Year>
          </Description>
        </CardItem>

        <CardItem1>
          <Image src={data[3].image} />
          <Description1>
            <Title>{data[3].title}</Title>
            {data[3].by}
            <Year>{data[3].year}</Year>
          </Description1>
        </CardItem1>
        <CardItem2>
          <Image src={data[4].image} />
          <Description2>
            <Title>{data[4].title}</Title>
            {data[4].by}
            <Year>{data[4].year}</Year>
          </Description2>
        </CardItem2>
      </Card>
      <CarouselContainer>
        <Carousel onChange={handleChange} />
      </CarouselContainer>
    </Container>
  );
};

export default AwardsSection;
