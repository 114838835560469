import { createContext, useEffect } from "react";
import gtmModule from "react-gtm-module";

export const GoogleTagManagerContext = createContext({
  handleEvent: (dataLayer: object, dataLayerName: string) => {},
});

interface IGoogleTagManagerProps {
  gtmId: string;
  dataLayer?: object;
  dataLayerName?: string;
}

const GoogleTagManagerProvider = ({
  gtmId,
  dataLayer,
  dataLayerName,
  children,
}: React.PropsWithChildren<IGoogleTagManagerProps>) => {
  useEffect(() => {
    gtmModule.initialize({ gtmId });
  }, [gtmId]);

  useEffect(() => {
    gtmModule.dataLayer({ dataLayer, dataLayerName });
  }, [dataLayer, dataLayerName]);

  const handleEvent = (dataLayer: object, dataLayerName: string) => {
    gtmModule.dataLayer({ dataLayer, dataLayerName });
  };

  return (
    <GoogleTagManagerContext.Provider value={{ handleEvent }}>
      {children}
    </GoogleTagManagerContext.Provider>
  );
};

export default GoogleTagManagerProvider;
