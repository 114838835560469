import awardsImage from "assets/images/Home/awards-india-500.png";
import AwardsCV from "assets/images/Home/awards-cv-magzine.png";
import {
  CardContainer,
  Container,
  Heading,
  ImageContainer,
} from "./AwardsSection.styles";

const AwardsSection = () => {
  return (
    <Container>
      <Heading>AWARDS & RECOGNITIONS</Heading>
      <CardContainer>
        <ImageContainer>
          <img src={awardsImage} alt="awards" />
          <img src={AwardsCV} alt="magzine" />
        </ImageContainer>
      </CardContainer>
    </Container>
  );
};

export default AwardsSection;
