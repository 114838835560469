import { useState } from "react";
import styled from "styled-components";
import AwardsSection from "../AwardsSection";
import ResearchSection from "../ResearchSection";
import { Menu } from "./RecognitionSection.styles";

const Container = styled.div`
  min-width: 400px;
  margin-bottom: 2rem;
  height: fit-content;
`;

const Content = styled.div`
  height: fit-content;
  max-width: 90%;
  margin: auto;
  overflow: auto;
  background: ${({ theme }) => theme.white};
  box-sizing: border-box;
`;

enum RecognitionSectionModules {
  awardsSection = "awardsSection",
  researchSection = "researchSection",
}

const RecognitionSection = () => {
  const [activeModule, setActiveModule] = useState<RecognitionSectionModules>(
    RecognitionSectionModules.awardsSection
  );

  const renderContent = () => {
    switch (activeModule) {
      case RecognitionSectionModules.awardsSection:
        return <AwardsSection />;

      case RecognitionSectionModules.researchSection:
        return <ResearchSection />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <Menu>
        <Menu.Menu>
          <Menu.Item
            active={activeModule === RecognitionSectionModules.awardsSection}
            onClick={() => {
              setActiveModule(RecognitionSectionModules.awardsSection);
            }}
            style={{
              "font-size": "2.5rem",
              "font-weight": "bold",
            }}
          >
            Awards
          </Menu.Item>

          <Menu.Item
            active={activeModule === RecognitionSectionModules.researchSection}
            onClick={() => {
              setActiveModule(RecognitionSectionModules.researchSection);
            }}
            style={{
              "font-size": "2.5rem",
              "font-weight": "bold",
            }}
          >
            Research Publication
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export default RecognitionSection;
