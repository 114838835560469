import {
  Content,
  Header,
  Description,
  LogoContainer,
  HeroBackgrond,
  Container,
} from "./HeroSection.styles";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { Logo, Navbar } from "components/common";
import * as links from "config/common/links";

const HeroSection = () => {
  return (
    <Container>
      <Navbar />
      <HeroBackgrond />
      <Content>
        <Header>
          NULL is disrupting Internet of Things and revolutionising the spread
          of data science
        </Header>
        <Description>
          NULL Innovation is a technology company offering IoT, AI enabled
          futuristic tech solutions in the areas of digital marketing and
          healthcare
        </Description>
      </Content>
      <LogoContainer>
        <Logo
          icon={LogoIcon}
          text="Dolphy"
          onClick={() => (window.location.href = links.navbar.dolphy)}
        />
        <Logo
          icon={LogoIcon}
          text="Survivr"
          onClick={() => (window.location.href = links.navbar.survivor)}
        />
      </LogoContainer>
    </Container>
  );
};

export default HeroSection;
