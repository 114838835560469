import styled, { css } from "styled-components";

const Container = styled.div`
  padding: 2rem 0;
  max-width: 90%;
  margin: auto;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;
const defaultCardStyle = css`
  display: grid;
  place-items: center;
  position: relative;
`;
const CardItem = styled.div`
  width: 300px;
  height: 280px;
  ${defaultCardStyle};
`;
const CardItem1 = styled.div`
  ${defaultCardStyle};
  width: 200px;
  height: 180px;
  opacity: 0.75;
`;
const CardItem2 = styled.div`
  ${defaultCardStyle};
  width: 150px;
  height: 130px;
  opacity: 0.5;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const Title = styled.p`
  &&& {
    font-weight: bold;
    color: #9e6b00;
  }
`;
const Description = styled.p`
  &&,
  && p {
    font-size: 1rem;
    color: ${({ theme }) => theme.lightGrey};
    text-align: center;
    margin: auto;
    max-width: 15ch;
  }
`;

const Year = styled.p`
  &&& {
    font-weight: bold;
    color: ${({ theme }) => theme.lightGrey};
  }
`;

const Description1 = styled.p`
  &&,
  && p {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.lightGrey};
    text-align: center;
    margin: auto;
    max-width: 15ch;
  }
`;

const Description2 = styled.p`
  &&,
  && p {
    font-size: 0.5rem;
    color: ${({ theme }) => theme.lightGrey};
    text-align: center;
    margin: auto;
    max-width: 15ch;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

export {
  Container,
  Card,
  CardItem,
  CardItem1,
  CardItem2,
  Image,
  Title,
  Description,
  Year,
  Description1,
  Description2,
  CarouselContainer,
};
