import styled from "styled-components";
import { Description } from "./Common.styles";

const SectionContainer = styled.div`
  margin: 144px auto;
  width: 76%;

  @media screen and (max-width: 767px) {
    margin: 96px auto;
  }
`;

const ContactNumber = styled(Description)`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export { SectionContainer, Description, ContactNumber };
