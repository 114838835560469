import styled from "styled-components";
import { ReactComponent as IllustrationImage } from "assets/images/Home/dolphyn-section-illustration.svg";
import { Logo as BaseLogo } from "components/common";

const Container = styled.div`
  padding: 4rem 0;
  min-height: 100vh;
  max-width: 80%;
  margin: auto;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`;

const FeaturesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 7rem;
  margin-bottom: 8rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

const Features = styled.ul`
  padding: unset;
  margin: unset;
`;

const Illustration = styled(IllustrationImage)`
  width: 70%;
  max-height: 100%;
`;

const Feature = styled.li`
  display: flex;
  justify-content: start;
  list-style: none;
  font-size: 2rem;
  padding: 1rem;
  text-align: start;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 8rem;
`;

const Logo = styled(BaseLogo)`
  gap: 2rem;
  font-family: ${({ theme }) => theme.alphaFont};
  cursor: default;
`;

export {
  Container,
  TextContainer,
  FeaturesContainer,
  Feature,
  Logo,
  Features,
  Illustration,
  Actions,
};
