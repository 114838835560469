import {
  BackgroundPicture,
  MainHeading,
  SectionContainer,
  SectionGrid,
} from "./HeroSection.styles";

import backgroundImage01 from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Top1.jpg";
import backgroundImage02 from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Top2.jpg";
import backgroundImage03 from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Top3.jpg";
import { useMediaQuery } from "hooks";

const HeroSection = () => {
  const isMobile = useMediaQuery(425);
  const isTablet = useMediaQuery(768);

  return (
    <SectionContainer>
      {isMobile ? (
        <SectionGrid>
          <BackgroundPicture src={backgroundImage02} />
          <MainHeading>NULL Co-Space for Founders and Freelancers</MainHeading>
        </SectionGrid>
      ) : isTablet ? (
        <SectionGrid>
          <BackgroundPicture src={backgroundImage01} />
          <BackgroundPicture src={backgroundImage03} />
          <MainHeading>NULL Co-Space for Founders and Freelancers</MainHeading>
        </SectionGrid>
      ) : (
        <SectionGrid>
          <BackgroundPicture src={backgroundImage01} />
          <BackgroundPicture src={backgroundImage02} />
          <BackgroundPicture src={backgroundImage03} />
          <MainHeading>NULL Co-Space for Founders and Freelancers</MainHeading>
        </SectionGrid>
      )}
    </SectionContainer>
  );
};

export default HeroSection;
