import styled from "styled-components";

const SectionContainer = styled.section`
  margin: 144px auto 72px;
  width: 76%;
`;

const SubHeading = styled.h3`
  color: ${({ theme }) => theme.lightGrey};
  text-align: center;
  margin: 16px auto 24px;
`;

const PlansContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 72px;

  @media screen and (max-width: 768px) {
    gap: 48px;
  }

  @media screen and (max-width: 475px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export { SectionContainer, PlansContainer, SubHeading };
