import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import Logo from "../Logo";
import { Menu, Navigation } from "./Navbar.styles";
import * as routes from "config/common/routes.config";
import * as links from "config/common/links";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();

  return (
    <Menu secondary>
      <Menu.Item>
        <Logo
          icon={LogoIcon}
          text="NULL"
          size="large"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(routes.home)}
        />
      </Menu.Item>
      <Navigation>
        <Menu.Item onClick={() => (window.location.href = links.navbar.dolphy)}>
          Dolphy
        </Menu.Item>
        <Menu.Item
          onClick={() => (window.location.href = links.navbar.survivor)}
        >
          Survivr
        </Menu.Item>
        <Menu.Item
          onClick={() => (window.location.href = links.navbar.aboutUs)}
        >
          About Us
        </Menu.Item>

        <Menu.Item onClick={() => (window.location.href = links.navbar.blogs)}>
          Blogs
        </Menu.Item>
      </Navigation>
    </Menu>
  );
};

export default Navbar;
