import {
  Benefit,
  BenefitHeading,
  BenefitPicture,
  SectionContainer,
} from "./BenefitSection.styles";

import Cafeteria from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Cafeteria.jpg";
import AccessAndSecurity from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_7_Days_access_Security.jpg";
import ConferenceRoom from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Conference_Room.jpg";
import DedicatedDesks from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_Dedicated_Desks.jpg";
import HighProductiveAtmoshphere from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_High_Productive_Atmoshphere.jpg";
import InternetAndWiFi from "assets/images/CoWorkSpace/Co-working-Space-in-Ahmedabad_NULL_Co-space_High_Speed_Internet.jpg";
import { useMediaQuery } from "hooks";

const BenefitSection = () => {
  const isMobile = useMediaQuery(767);

  return (
    <SectionContainer>
      {isMobile ? (
        <Benefit>
          <BenefitPicture src={InternetAndWiFi} alt="WiFi" />
          <BenefitHeading>High Speed Internet and Wifi</BenefitHeading>
          <BenefitPicture src={DedicatedDesks} alt="Dedicated Desks" />
          <BenefitHeading>Dedicated Desks</BenefitHeading>
          <BenefitPicture src={ConferenceRoom} alt="Conference Room" />
          <BenefitHeading>Conference Room</BenefitHeading>
          <BenefitPicture
            src={AccessAndSecurity}
            alt="7 Days Access and Security"
          />
          <BenefitHeading>7 Days Access and Security</BenefitHeading>
          <BenefitPicture
            src={HighProductiveAtmoshphere}
            alt="High Productive Atmoshphere"
          />
          <BenefitHeading>High Productive Atmoshphere</BenefitHeading>
        </Benefit>
      ) : (
        <Benefit>
          <BenefitPicture src={InternetAndWiFi} alt="WiFi" />
          <BenefitHeading>High Speed Internet and Wifi</BenefitHeading>
          <BenefitHeading>Dedicated Desks</BenefitHeading>
          <BenefitPicture src={DedicatedDesks} alt="Dedicated Desks" />
          <BenefitPicture src={ConferenceRoom} alt="Conference Room" />
          <BenefitHeading>Conference Room</BenefitHeading>
          <BenefitHeading>7 Days Access and Security</BenefitHeading>
          <BenefitPicture
            src={AccessAndSecurity}
            alt="7 Days Access and Security"
          />
          <BenefitPicture
            src={HighProductiveAtmoshphere}
            alt="High Productive Atmoshphere"
          />
          <BenefitHeading>High Productive Atmoshphere</BenefitHeading>
          <BenefitHeading>Cafeteria</BenefitHeading>
          <BenefitPicture src={Cafeteria} alt="Cafeteria" />
        </Benefit>
      )}
    </SectionContainer>
  );
};

export default BenefitSection;
