import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  * {
    box-sizing: border-box;
  }
  body {
    font-size: 10px;   
    font-family: 'Montserrat',sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Montserrat',sans-serif;
    }
  }
  scroll-behavior: smooth;
}
`;

export default GlobalStyle;
