import styled from "styled-components";

const SectionContainer = styled.section`
  margin: 0 auto 72px;
`;

const SectionGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 84vh;

  @media screen and (max-width: 768px) {
    height: 76vh;
  }
`;

const BackgroundPicture = styled.img`
  height: 100%;
  width: 100%;
  opacity: 0.4;
`;

const MainHeading = styled.h2`
  position: absolute;
  top: 48%;
  width: 100%;
  text-align: center;
  font-size: 48px;
  letter-spacing: 1.7px;
  color: ${({ theme }) => theme.beta};
  margin: unset;

  span {
    color: ${({ theme }) => theme.beta};
  }

  @media screen and (max-width: 768px) {
    top: 40%;
    padding: 0 32px;
    font-size: 40px;
  }

  @media screen and (max-width: 525px) {
    top: 32%;
  }
`;

export { SectionContainer, SectionGrid, MainHeading, BackgroundPicture };
