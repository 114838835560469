import styled from "styled-components";

const Container = styled.div`
  max-width: 30%;
  @media screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const Header = styled.h2`
  margin-bottom: 3rem;
`;

const LinkItem = styled.div`
  margin-bottom: 2rem;
  cursor: pointer;
`;

type Link = {
  title: string;
  onClick: (...args: any[]) => void;
};

interface IProps {
  header: string;
  data: Link[];
}

const LinksList = ({ header, data }: IProps) => {
  return (
    <Container>
      <Header>{header}</Header>
      {data.map(({ title, onClick }, key) => (
        <LinkItem key={key} onClick={onClick}>
          {title}
        </LinkItem>
      ))}
    </Container>
  );
};

export default LinksList;
