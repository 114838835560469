import styled from "styled-components";
import { darken } from "polished";
import { Menu as BaseMenu } from "semantic-ui-react";

const Menu = styled(BaseMenu)`
  &&&& {
    background-color: ${({ theme }) => theme.alpha};
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    padding: 1rem 2rem;
    margin: unset;
    .item {
      color: ${({ theme }) => theme.white};
      font-family: ${({ theme }) => theme.alphaFont};
      font-weight: 500;
      &:hover {
        background-color: ${({ theme }) => theme.alpha};
        color: ${({ theme }) => darken("0.1", theme.white)};
      }
    }
  }
`;

const Navigation = styled(Menu.Menu).attrs(() => ({ position: "right" }))`
  &&&&& {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export { Menu, Navigation };
