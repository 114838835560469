import { useCallback, useEffect, useState } from "react";

const getMediaQueryMatch = (width = 767): boolean | undefined =>
  window ? window.matchMedia(`(max-width: ${width}px)`).matches : undefined;

/**
 * isMatches is undefined before hook effect
 * @param width to make max-width media query. Default is 767
 * @returns {undefined | boolean} Is media query matches. Undefined if there is no window reference yet
 */
const useMediaQuery = (width = 767): undefined | boolean => {
  const [isMatches, setIsMatches] = useState<undefined | boolean>(
    getMediaQueryMatch(width)
  );
  const onChange = useCallback(
    () => setIsMatches(getMediaQueryMatch(width)),
    [width]
  );

  useEffect(() => {
    onChange();
    window.addEventListener("resize", onChange);
    return () => window.removeEventListener("resize", onChange);
  }, [onChange]);

  return isMatches;
};

export default useMediaQuery;
