import styled from "styled-components";
import { ReactComponent as Illustration } from "assets/images/Home/subscribe-now-image.svg";
import {
  Form as SemanticForm,
  Input as SemanticInput,
} from "semantic-ui-react";
import { Button as BaseButton } from "components/core";

const Container = styled.div`
  max-width: 80%;
  margin: auto;
  margin-bottom: 8rem;
`;

const SubContainer = styled.div`
  background-color: ${({ theme }) => theme.beta};
  padding: 5rem 2rem;
  position: relative;
`;

const Header = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-size: 3rem;
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 30 + 5px);
  }
`;

const Input = styled(SemanticInput)`
  &&&& {
    height: 100%;
  }
`;

const SubscribeNowBackground = styled(Illustration)`
  height: 110%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
`;

const Form = styled(SemanticForm)`
  &&&& {
    position: relative;
    z-index: 1;
    max-width: 70%;
    margin: auto;
    .fields {
      @media screen and (max-width: 768px) {
        gap: 2rem;
      }
    }
  }
`;

const Button = styled(BaseButton)`
  &&&&& {
    padding: 1.2rem 1rem;
    font-size: 1rem;
    width: 100%;
    border: none;
  }
`;

export {
  Container,
  SubscribeNowBackground,
  SubContainer,
  Header,
  Input,
  Form,
  Button,
};
