import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.alpha};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 4rem 1.2rem 1.2rem 1.2rem;
`;

const Header = styled.h3`
  color: ${({ theme }) => theme.beta};
  font-size: 1.2rem;
`;

interface IProps {
  header: string;
  description: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const FeatureCard = ({ header, description, icon: Icon }: IProps) => {
  return (
    <Container>
      <Icon />
      <Header>{header}</Header>
    </Container>
  );
};

export default FeatureCard;
