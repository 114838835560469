import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle, theme } from "theme";
import { constants } from "config/common";
import App from "./App";

import "semantic-ui-css/semantic.min.css";
import { GoogleTagManagerProvider } from "context/common";

const queryClient = new QueryClient();

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GoogleTagManagerProvider gtmId={constants.GTM_ID}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </GoogleTagManagerProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
