const data = [
  {
    heading: "Freelancers",
    features: [
      "One or two people team",
      "Dedicated Desk",
      "Free WiFi",
      "Meeting Room Access",
      "7 days availability",
      "Tea/Coffee",
    ],
    amount: 5000,
  },
  {
    heading: "StartUp Team",
    features: [
      "More than 3 people team",
      "Dedicated Desk",
      "Free WiFi",
      "Meeting Room Access",
      "7 days availability",
      "Tea/Coffee",
    ],
    amount: 5000,
  },
];

export default data;
