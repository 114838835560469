import styled from "styled-components";

const TeamSection = styled.section`
  margin: 24px auto;
  width: 83.4%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  column-gap: 48px;
  row-gap: 48px;
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-bold: 20px;
  font-weight: bold;
`;

const Container = styled.div`
  margin: auto;
  max-width: 80%;
`;

export { TeamSection, Heading, Container };
