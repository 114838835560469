import styled from "styled-components";

const SectionContainer = styled.section`
  margin: 144px auto;
  width: 76%;

  @media screen and (max-width: 767px) {
    margin: 96px auto;
  }
`;

export { SectionContainer };
