import styled from "styled-components";

const Container = styled.div`
  padding: 2rem 0;
  max-width: 100%;
  margin: auto;
  margin-bottom: 3rem;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
`;

const CardItem = styled.div`
  max-width: 400px;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.lightGrey};
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

export { Container, Card, CardItem, Image, Description, CarouselContainer };
