import styled from "styled-components";

const SectionContainer = styled.section`
  margin: 0 auto;
  padding: 24px 0;
  width: 83.4%;
  display: flex;
  align-items: center;
`;

const Navbar = styled.nav`
  box-shadow: 0px 0px 3px 0 #dee6dc;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const BrandTitle = styled.h2`
  color: ${({ theme }) => theme.beta};
  margin: unset;
  margin-left: 12px;
`;

const ChatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  span {
    font-size: 20px;
  }
`;

export { SectionContainer, BrandTitle, Navbar, ChatWrapper };
