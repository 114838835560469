import styled from "styled-components";

const Container = styled.div`
  max-width: 80%;
  margin: auto;
  margin-bottom: 8rem;
`;

const Header = styled.h5`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5rem;
`;
const Card = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 3rem;
`;

const CardItem = styled.div`
  max-width: 400px;
`;

const Company = styled.img`
  width: 150px;
`;
const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

export { Container, Card, CardItem, Header, Company, CarouselContainer };
