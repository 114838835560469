import { HTMLAttributes } from "react";
import { Container, Text, IconContainer } from "./Logo.styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string;
  variant?: "alpha" | "beta";
  size: "small" | "large";
}

const defaultProps = {
  size: "small",
};

const Logo = ({ icon: Icon, variant, text, size, ...rest }: IProps) => {
  return (
    <Container {...rest}>
      <IconContainer size={size} variant={variant}>
        <Icon />
      </IconContainer>
      <Text size={size} variant={variant}>
        {text}
      </Text>
    </Container>
  );
};

Logo.defaultProps = defaultProps;

export default Logo;
