import styled from "styled-components";

const Container = styled.div`
  margin: auto;
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-bold: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const ImageContainer = styled.div`
  gap: 5rem;
  display: flex;
  justify-content: space-evenly;
  height: 350px;
  width: 350px;
  box-shadow: 0 0 4px 0 #555555;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 3rem;
`;

export { Container, Heading, ImageContainer, CardContainer };
