import { lighten } from "polished";
import { Button as SemanticButton } from "semantic-ui-react";
import styled, { css } from "styled-components";
import { IProps } from "./Button";

const StyledButton = styled(SemanticButton)<IProps>`
  &&&& {
    border-radius: 15px;
    border: 1px solid;
    padding: 2rem 4rem;
    font-size: 2rem;
    background-color: ${({ theme, variant }) => theme[variant]};
    color: ${({ theme, variant }) => lighten("0.9", theme[variant])};
    @media screen and (max-width: 768px) {
      font-size: calc((50vw + 50vh) / 40 + 4px);
      padding: calc((50vw + 50vh) / 40 + 4px) calc((50vw + 50vh) / 20 + 4px);
    }
    &:hover {
      background-color: ${({ theme, variant }) =>
        lighten("0.05", theme[variant])};
    }
    ${({ outline, variant, theme }) => {
      if (outline) {
        return css`
          background-color: unset;
          border-color: ${theme[variant]};
          color: ${theme[variant]};
          &:hover {
            background-color: ${theme[variant]};
            color: ${lighten("0.9", theme[variant])};
          }
        `;
      }
    }};
  }
`;

export { StyledButton };
