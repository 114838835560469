import { WhatsappIcon } from "react-share";
import { ReactComponent as BrandLogo } from "assets/icons/brand-logo.svg";

import {
  BrandTitle,
  SectionContainer,
  Navbar,
  ChatWrapper,
} from "./Header.styles";

const Header = () => {
  return (
    <Navbar>
      <SectionContainer>
        <BrandLogo /> <BrandTitle>NULL</BrandTitle>
        <ChatWrapper>
          <span>Chat on </span>
          <WhatsappIcon
            size={24}
            round={true}
            cursor="pointer"
            onClick={() => window.open("https://wa.me/+919327393548")}
          />
        </ChatWrapper>
      </SectionContainer>
    </Navbar>
  );
};

export default Header;
