import { useCallback, useState } from "react";
import {
  Container,
  Header,
  CardContainer,
  CardItem,
  CarouselContainer,
  Company,
  Heading,
} from "./EcosystemSection.styles";
import { Carousel } from "components/common";
import company1 from "assets/images/company_logos/memjet.webp";
import company2 from "assets/images/company_logos/company2.png";
import company3 from "assets/images/company_logos/company3.png";
import company4 from "assets/images/company_logos/company4.png";
import company5 from "assets/images/company_logos/company5.png";
import company6 from "assets/images/company_logos/elevate-energy.webp";
import company7 from "assets/images/company_logos/company7.png";
import company8 from "assets/images/company_logos/company8.png";
import company9 from "assets/images/company_logos/company9.png";
import company10 from "assets/images/company_logos/company10.png";
import company11 from "assets/images/company_logos/company11.png";
import company12 from "assets/images/company_logos/company12.png";
import company13 from "assets/images/company_logos/company13.png";
import company14 from "assets/images/company_logos/company14.png";
import gusec_uni from "assets/images/Home/gusec_uni.png";
import t_hub from "assets/images/Home/t_hub.png";
import cie from "assets/images/Home/cie-logo-final.png";

interface CardData {
  id: number;
  image: string;
}

const cardsData: CardData[] = [
  {
    id: 1,
    image: company1,
  },
  {
    id: 2,
    image: company2,
  },
  {
    id: 3,
    image: company3,
  },
  {
    id: 4,
    image: company4,
  },
  {
    id: 5,
    image: company5,
  },
  {
    id: 6,
    image: company6,
  },
  {
    id: 7,
    image: company7,
  },
  {
    id: 8,
    image: company8,
  },
  {
    id: 9,
    image: company9,
  },
  {
    id: 10,
    image: company10,
  },
  {
    id: 11,
    image: company11,
  },
  {
    id: 12,
    image: company12,
  },
  {
    id: 13,
    image: company13,
  },
  {
    id: 14,
    image: company14,
  },
];

const EcosystemSection = () => {
  const [data, setData] = useState<
    [CardData, CardData, CardData, CardData, CardData, CardData]
  >([
    { id: 1, image: "" },
    { id: 2, image: "" },
    { id: 3, image: "" },
    { id: 4, image: "" },
    { id: 5, image: "" },
    { id: 6, image: "" },
  ]);

  const handleChange = useCallback((val: number) => {
    const newData = cardsData.filter(
      ({ id }) =>
        id === val ||
        id === val + 2 ||
        id === val + 4 ||
        id === val + 7 ||
        id === val + 10 ||
        id === val + 11 ||
        id === val + 12
    );
    setData(() => [
      newData[0],
      newData[1],
      newData[2],
      newData[3],
      newData[4],
      newData[5],
    ]);
  }, []);

  return (
    <Container>
      <Heading>ECOSYSTEM PARTNER</Heading>

      <CardContainer>
        <img src={gusec_uni} alt="gusec" />
        <img src={t_hub} alt="hub" />
        <img src={cie} alt="logo-final" />
      </CardContainer>
      <Header>Used by 1000+ organizations across the world</Header>

      <CardContainer>
        {data.map(({ image }, key) => (
          <CardItem>
            <Company key={key} src={image} alt="company-logo" />
          </CardItem>
        ))}
      </CardContainer>
      <CarouselContainer>
        <Carousel onChange={handleChange} />
      </CarouselContainer>
    </Container>
  );
};

export default EcosystemSection;
