import { ReactComponent as CircleIcon } from "assets/images/Home/customer-pic-circle.svg";
import { ReactComponent as SemiCircleIcon } from "assets/images/Home/customer-pic-semicircle.svg";
import styled from "styled-components";

const Container = styled.div`
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 8rem;
  div,
  p {
    font-family: ${({ theme }) => theme.gammaFont};
  }
`;

const TextContainer = styled.div`
  max-width: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 350px;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`;

const Circle = styled(CircleIcon)`
  position: absolute;
  bottom: 0;
  left: 2%;
  z-index: -1;
`;

const SemiCircle = styled(SemiCircleIcon)`
  position: absolute;
  top: 0;
  right: 2%;
  z-index: -1;
`;

const Header = styled.h1`
  font-size: 3rem;
  span {
    color: ${({ theme }) => theme.beta};
  }
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 30 + 5px);
  }
  margin-bottom: 3rem;
`;

const SubHeader = styled.h4`
  font-family: ${({ theme }) => theme.gammaFont};
  span {
    display: inline-block;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 768px) {
      width: 150px;
    }
  }
  margin-bottom: 2rem;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.lightGrey};
  margin-bottom: 3rem;
`;

const Name = styled.h3``;
const City = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.lightGrey};
`;

export {
  Container,
  TextContainer,
  ImageContainer,
  Image,
  Circle,
  SemiCircle,
  Header,
  SubHeader,
  Description,
  Name,
  City,
};
