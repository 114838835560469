import { v4 as uuid } from "uuid";
import { Button } from "components/core";
import {
  Card,
  ExtraInfo,
  FeatureItem,
  FeatureList,
  Heading,
  PriceContainer,
} from "./PlanCard.styles";

type Props = {
  heading: string;
  features: string[];
  amount: number;
};

const PlanCard = ({ heading, features, amount }: Props) => {
  return (
    <Card>
      <Heading>{heading}</Heading>
      <FeatureList>
        {features.map((feature) => (
          <FeatureItem key={uuid()}>{feature}</FeatureItem>
        ))}
      </FeatureList>
      <PriceContainer>
        <Button
          variant="beta"
          content={`₹${amount}/ Including all`}
          style={{
            fontSize: "16px",
            padding: "12px 16px",
            borderRadius: "8px",
          }}
        />
        <ExtraInfo>Per Desk</ExtraInfo>
      </PriceContainer>
    </Card>
  );
};

export default PlanCard;
