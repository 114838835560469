import {
  CardContainer,
  IconButton,
  LinkedinIcon,
  MemberName,
  MemberPicture,
  MemberRole,
} from "./MemberCard.style";

import linkedinIcon from "assets/images/Home/linkedin.svg";

type Props = {
  name: string;
  role: string;
  pic: string;
  link: string;
};

const MemberCard = ({ name, role, pic, link }: Props) => {
  return (
    <CardContainer>
      <MemberPicture src={pic} alt={name} />
      <MemberName>{name}</MemberName>
      <MemberRole>{role}</MemberRole>
      <IconButton onClick={() => window.open(link)}>
        <LinkedinIcon src={linkedinIcon} alt="linkedin" />
      </IconButton>
    </CardContainer>
  );
};

export default MemberCard;
