import styled from "styled-components";

const SubHeader = styled.h1`
  color: ${({ theme }) => theme.darkGrey};
  font-size: 3.75rem;
  margin-bottom: 2.4rem;
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 20 + 2px);
  }
`;

export default SubHeader;
