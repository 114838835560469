import {
  Container,
  InnerContainer,
  TextContainer,
  FeaturesContainer,
  Actions,
} from "./SurvivorSection.styles";
import { Button, Description, Header, SubHeader } from "components/core";
import FeatureCard from "./FeatureCard";
import { ReactComponent as BloodPressureIcon } from "assets/icons/Survivr/blood-pressure.svg";
import { ReactComponent as BodyTemperatureIcon } from "assets/icons/Survivr/body-temperature.svg";
import { ReactComponent as HeartrateIcon } from "assets/icons/Survivr/heart-rate.svg";
import { ReactComponent as BloodOxygenIcon } from "assets/icons/Survivr/blood-oxygen.svg";
import { ReactComponent as EmotionalStatusIcon } from "assets/icons/Survivr/emotional-status.svg";
import { ReactComponent as LocationTrackerIcon } from "assets/icons/Survivr/location-tracker.svg";

import * as links from "config/common/links";

const featureCardsData = [
  {
    header: "Blood Pressure",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: BloodPressureIcon,
  },
  {
    header: "Body Temperature",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: BodyTemperatureIcon,
  },
  {
    header: "Heart Rate",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: HeartrateIcon,
  },
  {
    header: "Blood Oxygen Level",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: BloodOxygenIcon,
  },
  {
    header: "Emotional Status",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: EmotionalStatusIcon,
  },
  {
    header: "Location Tracker",
    description:
      "Survivr is portable wireless device that collects the Heart Rate, Blood Pressure, Blood Oxygen Level, Temperature ",
    icon: LocationTrackerIcon,
  },
];

const SurvivorSection = () => {
  return (
    <Container>
      <InnerContainer>
        <TextContainer>
          <Header>Survivr</Header>
          <SubHeader>
            A Health Care Wireless Device That Monitors Vital Signs
          </SubHeader>
          <Description>
            Survivr is an IoT – enabled medical device that collects the Heart
            Rate, Blood Pressure, Blood Oxygen Level, Temperature and Emotional
            data of the person. It informs the relatives and doctor on the same
            time and alerts on finding unusual activities.
          </Description>
        </TextContainer>

        <FeaturesContainer>
          {featureCardsData.map((data) => (
            <FeatureCard {...data} />
          ))}
        </FeaturesContainer>
        <Actions>
          <Button
            variant="beta"
            outline
            content="Watch Video"
            onClick={() =>
              window.open(
                "https://www.youtube.com/embed/ujFnbY_Q4pU?start=0",
                "newwindow",
                "width=700,height=600"
              )
            }
          />
          <Button
            variant="beta"
            content="Learn More"
            onClick={() => (window.location.href = links.navbar.survivor)}
          />
        </Actions>
      </InnerContainer>
    </Container>
  );
};

export default SurvivorSection;
