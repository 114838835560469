import { ButtonProps } from "semantic-ui-react";
import { StyledButton } from "./Button.styles";

export type variant = "alpha" | "beta" | "gamma";

export interface IProps extends ButtonProps {
  variant: variant;
  outline?: boolean;
  style?: React.CSSProperties;
}

const defaultProps = {
  outline: false,
};

const Button = (props: IProps) => {
  return <StyledButton {...props} />;
};

Button.defaultProps = defaultProps;

export default Button;
