export const theme = {
  alpha: "#1F3057",
  beta: "#355FA3",
  gamma: "#FF8B4A",
  delta: "#F7FFF4",
  success: "#4ECE00",
  danger: "#EA4335",
  black: "#000000",
  darkGrey: "#5F5F5F",
  lightGrey: "#868686",
  white: "#FFFFFF",
  alphaFont: `'Montserrat', sans-serif`,
  betaFont: `'Poppins', sans-serif`,
  gammaFont: `'Nunito Sans', sans-serif`,
};

declare module "styled-components" {
  type theme = typeof theme;
  export interface DefaultTheme extends theme {}
}
