import axios from "axios";

const hubSpot = axios.create({
  baseURL: "https://api.hsforms.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default hubSpot;
