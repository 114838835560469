import { useState } from "react";
import { heroSectionData } from "config";
import {
  Container,
  Button,
  Content,
  ButtonContainer,
} from "./HeroSection.styles";

const HeroSection = () => {
  const [currentText, setCurrentText] = useState(heroSectionData["about-us"]);

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={() => setCurrentText(heroSectionData["about-us"])}>
          About US
        </Button>
        <Button onClick={() => setCurrentText(heroSectionData["mission"])}>
          MISSION
        </Button>
        <Button onClick={() => setCurrentText(heroSectionData["vision"])}>
          VISION
        </Button>
      </ButtonContainer>
      <Content>{currentText}</Content>
    </Container>
  );
};

export default HeroSection;
