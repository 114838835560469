import { v4 as uuid } from "uuid";
import { plans } from "config/CoWorkSpace";
import PlanCard from "./PlanCard";
import { Description, Heading } from "./Common.styles";
import {
  PlansContainer,
  SectionContainer,
  SubHeading,
} from "./PlanSection.styles";

const PlanSection = () => {
  return (
    <SectionContainer>
      <Description>
        At NULL Co-working space, we know that your success is our success.
        That's why we provide a supportive and inclusive environment where you
        can connect with like-minded individuals and grow your business. You
        will receive all offerings with single. affordable price. What are you
        waiting for?
      </Description>
      <Heading>Pricing</Heading>
      <SubHeading>Most affordable Price, No Hidden Fees</SubHeading>
      <PlansContainer>
        {plans.map((plan) => (
          <PlanCard
            key={uuid()}
            heading={plan.heading}
            features={plan.features}
            amount={plan.amount}
          />
        ))}
      </PlansContainer>
    </SectionContainer>
  );
};

export default PlanSection;
