import React, { useContext } from "react";
import {
  Container,
  SubscribeNowBackground,
  SubContainer,
  Header,
  Input,
  Form,
  Button,
} from "./SubscribeNow.styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Message } from "semantic-ui-react";
import { SubscribeContext } from "context";

const intialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Please enter a valid email."),
});

const SubscribeNowSection = () => {
  const { subscribe, success, error } = useContext(SubscribeContext);
  const handleSubmit = (values: typeof intialValues) => subscribe(values.email);

  return (
    <Container>
      <SubContainer>
        <Header> Let us send you offering</Header>
        <Formik
          initialValues={intialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form error onSubmit={props.handleSubmit} noValidate>
              <Form.Group>
                <Form.Field width="12">
                  <Input
                    type="email"
                    value={props.values.email}
                    onChange={(e: React.ChangeEvent, { value }: any) =>
                      props.setFieldValue("email", value)
                    }
                    onBlur={() => props.setFieldTouched("email", true)}
                    placeholder="Enter your email address here"
                  />
                </Form.Field>
                <Form.Field width="4">
                  <Button
                    variant="gamma"
                    content="Subscribe Now"
                    type="submit"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field width="12">
                <Message
                  error
                  content={props.errors.email || error}
                  hidden={
                    error === "" && !(props.touched.email && props.errors.email)
                  }
                />
                <Message
                  style={{ display: success === "" ? "none" : "block" }}
                  success
                  content={success}
                />
              </Form.Field>
            </Form>
          )}
        </Formik>
        <SubscribeNowBackground />
      </SubContainer>
    </Container>
  );
};

export default SubscribeNowSection;
