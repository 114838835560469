import { useState } from "react";
import { Carousel } from "components/common";
import customerImage from "assets/images/Home/customer-pic.png";
import {
  Container,
  TextContainer,
  ImageContainer,
  Image,
  Circle,
  SemiCircle,
  Header,
  SubHeader,
  Description,
  Name,
  City,
} from "./CustomerStories.styles";

const customerStoriesData = [
  {
    id: 1,
    description: `The team at Null Innovations, has extensive experience in IoT that helped us build our products at rapid pace. We will continue our collaboration for future products to come`,
    name: "BHASKAR. S",
    city: "DIRECTOR",
    country: "SOCLOGIC INC",
    image: customerImage,
  },
  {
    id: 2,
    description: `We had a stingent deadlines converting our product portfolio with HW capabilities. Null was a great partnership we underwent to helpourselves to quickly gain required expertise`,
    name: "TRIVEDI.L",
    city: "CTO",
    country: "GOTWEET, INDIA",
    image: customerImage,
  },
  {
    id: 3,
    description: `Do not know Twitter could be so effective in lead generation. We have significantly increased our Twitter presence and engagement through Dolphy's EasyTwitter. Saved my significant time. I recommend`,
    name: "ROHIT SHETTY",
    city: "RIGHTEOUSTECH.IN",
    country: "INDIA",
    image: customerImage,
  },
];

type Story = {
  id: number;
  description: string;
  name: string;
  city: string;
  country: string;
  image: string;
};

const CustomerStories = () => {
  const [data, setData] = useState<Story>({
    id: 0,
    description: "",
    name: "",
    city: "",
    country: "",
    image: "",
  });

  const handleCarouselChange = (val: number) => {
    setData(customerStoriesData.filter(({ id }) => id === val)[0]);
  };

  return (
    <Container>
      <TextContainer>
        <Header>
          Our <span>Customer</span> Stories
        </Header>
        <SubHeader>
          &#x201C;<span>{data.description}</span>&#x201D;
        </SubHeader>
        <Description>{data.description}</Description>
        <Name>{data.name}</Name>
        <City>
          {data.city}, {data.country}
        </City>
        <Carousel onChange={handleCarouselChange} />
      </TextContainer>
      <ImageContainer>
        <Image src={data.image} alt="customer-image" />
        <Circle />
        <SemiCircle />
      </ImageContainer>
    </Container>
  );
};

export default CustomerStories;
