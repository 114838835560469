import styled, { css } from "styled-components";

type TText = {
  variant?: "alpha" | "beta";
  size: "small" | "large";
};

type TIcon = TText;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.span<TText>`
  font-family: ${({ theme }) => theme.betaFont};
  font-weight: bold;
  line-height: 2rem;
  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          font-size: 1.6rem;
          font-weight: normal;
          @media screen and (max-width: 768px) {
            font-size: calc((50vw + 50vh) / 40 + 5px);
          }
        `;

      case "large":
        return css`
          font-size: 2.6rem;
          @media screen and (max-width: 768px) {
            font-size: calc((50vw + 50vh) / 30 + 5px);
          }
        `;

      default:
        return css`
          font-size: 1rem;
        `;
    }
  }};
  margin: 0 0.5rem;
  color: ${({ theme, variant }) => {
    if (variant) return theme.darkGrey;
    return theme.white;
  }};
`;

const IconContainer = styled.span<TIcon>`
  svg {
    width: 76px;
  }
  svg rect {
    fill: ${({ theme, variant }) => {
      if (variant) return theme[variant];
      return theme.white;
    }};
  }
`;

export { Container, Text, IconContainer };
