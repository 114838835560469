import styled from "styled-components";

const Card = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 3px 0 ${({ theme }) => theme.lightGrey};
  border-radius: 8px;
  width: 324px;
  padding: 48px 0;
  text-align: center;

  &:hover {
    box-shadow: 0px 0px 5px 0 ${({ theme }) => theme.lightGrey};
  }

  @media screen and (max-width: 525px) {
    width: 275px;
  }
`;

const Heading = styled.h2`
  color: ${({ theme }) => theme.beta};
`;

const FeatureList = styled.ul`
  margin: 32px 0;
  list-style-type: none;
  padding: unset;
`;

const FeatureItem = styled.li`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ExtraInfo = styled.span`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 600;
`;

export { Card, Heading, FeatureList, FeatureItem, ExtraInfo, PriceContainer };
