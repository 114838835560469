import styled from "styled-components";
import { ReactComponent as Background } from "assets/icons/hero-background.svg";

const Content = styled.div`
  position: relative;
  z-index: 2;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 5rem;
  max-width: 1064px;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 3.8rem;
  font-weight: 900;
  margin-bottom: 3rem;
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 20 + 5px);
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 1.6rem;
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 40 + 2px);
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5rem;
  padding: 0 5rem;
  max-width: 1064px;
  margin: 5rem 0;
  position: relative;
  z-index: 2;
`;

const HeroBackgrond = styled(Background)`
  max-height: 80%;
  max-width: 100%;
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  overflow: hidden;
`;

const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.alpha};
  display: flex;
  flex-direction: column;
`;

export {
  Content,
  Header,
  Description,
  LogoContainer,
  HeroBackgrond,
  Container,
};
