import styled from "styled-components";

const Container = styled.div`
  margin: 2rem auto;
  padding: 5rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Button = styled.button`

background-color:${({ theme }) => theme.alpha};
color: White;
font-size: 18px;
margin: 1em;
padding: 0.25em 1em;
border: 2px solid ${({ theme }) => theme.alpha};;
border-radius: 3px;
&:hover {
  background-color:background-color: palevioletred;
  color: white;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;

  background: linear-gradient(to right, #62b69a, #1f7e62);
  border: unset;
  font-size: 14px;
  padding: 12px 24px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: Green;
    color: White;
    cursor: pointer;

  &:hover {
    background: linear-gradient(to right, #50ae8f, #067050);
  }
`;

const Content = styled.div`
  margin: 0 12rem;
  padding: 5rem 2rem;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
`;

export { Container, Button, Content, ButtonContainer };
